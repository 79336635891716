import styled, { css } from 'styled-components'
import { Button } from 'antd'

export const AntButton = styled(Button)`
  padding: 0.5rem;
  height: auto !important;
  min-width: 20rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 30px;
  border-radius: 5px;
`
