import { message } from 'antd'
import i18n from '@/i18n'

const getErrorKey = (code, defaultError) => {
  switch (code) {
    case 'paypalEmail.recorded':
      return 'validate.paypalEmailRecorded'

    case 'user.exists':
      return 'validate.userExist'

    case 'invalid.referral':
      return 'invalid.referral'

    case 'user.notVerified':
      return 'validate.userNotVerified'

    case 'email.invalid':
      return 'validate.email_invalid'

    case 'email.unregistered':
      return 'validate.email_unregistered'

    case 'user.inactive':
      return 'validate.user_inactive'

    case 'user.invalid':
      return 'validate.user.invalid'

    case 'operator.exists':
      return 'validate.operatorExist'

    case 'token.expired':
      return 'validate.token.expired'

    case 'password.notMatch':
      return 'validate.password.notMatch'

    case 'triangleTest.alreadyAnswered':
      return 'validate.triangleTest.alreadyAnswered'

    case 'password.invalid':
      return 'validate.password.invalid'

    default:
      return defaultError
  }
}

const ErrorMessage = (text, code, defaultError) => {
  if (!text && code && defaultError) {
    const errorKey = getErrorKey(code, defaultError)

    message.destroy()
    message.error(i18n.t(errorKey))
  }

  if (text) {
    message.destroy()
    message.error(text)
  }
}

export default ErrorMessage
