import React, { useMemo } from 'react'
import { StyledButton, Text, Icon } from './styles'
import { MenuOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ErrorMessage from '@/utils/messageError'

const SidebarToggle = ({ toggle, setToggle, showPopup }) => {
  const { unanswered } = useSelector(state => state.answer)
  const { theme } = useSelector(state => state.survey)
  const isConfectionery = useMemo(() => theme === 'Confectionery', [theme])

  const { t } = useTranslation()
  const showError = () =>
    ErrorMessage(
      t(`components.${process.env.REACT_APP_THEME}.unAnsweredPopup.title`)
    )
  return (
    <StyledButton
      onClick={() =>
        unanswered
          ? isConfectionery
            ? showError()
            : showPopup(true)
          : setToggle(!toggle)
      }
    >
      <Icon>
        <MenuOutlined />
      </Icon>
      {!isConfectionery && (
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.menu`)}
        </Text>
      )}
    </StyledButton>
  )
}
export default SidebarToggle
