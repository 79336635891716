import styled from 'styled-components'

export const StyledButton = styled.div`
  padding: 0.2rem 0.7rem;
  display: flex;
  align-items: center;
  height: 20px;
  height: auto;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &:hover span {
    text-decoration: underline;
  }
`
export const Text = styled.span`
  color: ${props => props.theme.DEFAULT_TEXT};
  font-size: 0.8rem;
  line-height: 1rem;
  margin-left: 0.8rem;
`
export const Icon = styled.span`
  background-color: #F7F7FC;
  color: ${props => props.theme.DARK};
  height: auto;
  display: flex;
  justify-items: center;
  padding: 10px;
  border-radius: 0.4rem;
}
`
