import styled ,{css} from 'styled-components'
import { Button } from 'antd'

export const Primary = styled(Button)`
  padding: 0.5rem 2rem;
  height: auto !important;
  font-size: 1rem;
  text-transform : uppercase;
  font-weight: bold;
  width: fit-content;
  margin-top: 1rem;
  border_radius: 5px;

  ${({confirmation})=> !!confirmation && css`
    min-width: 16rem;
  `}

`
export const Default = styled(Button)`
  padding: 0.5rem 2rem;
  height: auto !important;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  width: fit-content;
  margin-top: 1rem;
  border_radius: 5px;
  
  ${({confirmation})=> !!confirmation && css`
    min-width: 16rem;
  `}
  ${({qrConfirmation})=> !!qrConfirmation && css`
    font-size: 0.7rem;
  `}
`