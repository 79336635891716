import React, { useMemo } from 'react'
import { StyledButton, Text, Icon } from './styles'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ErrorMessage from '@/utils/messageError'

const BackButton = ({ showPopup }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { unanswered, attachedQuestionAnswerCompulsory } = useSelector(
    state => state.answer
  )
  const { theme } = useSelector(state => state.survey)
  const themeCondition = useMemo(
    () =>
      history.location.pathname.includes(
        `${theme === 'Confectionery' ? 'product' : 'painpoint'}`
      ),
    [theme]
  )
  const isConfectionery = useMemo(() => theme === 'Confectionery', [theme])
  return history.location.pathname.includes('review') ||
    !themeCondition ? null : (
    <StyledButton
      onClick={() => {
        if (unanswered || attachedQuestionAnswerCompulsory) {
          isConfectionery
            ? ErrorMessage(
                t(
                  `components.${process.env.REACT_APP_THEME}.unAnsweredPopup.title`
                )
              )
            : showPopup(true)
        } else {
          if (!themeCondition) {
            history.goBack()
          } else {
            history.goBack()
          }
        }
      }}
    >
      <Icon>
        <ArrowLeftOutlined />
      </Icon>
      {theme !== 'Confectionery' && (
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.back`)}
        </Text>
      )}
    </StyledButton>
  )
}
export default BackButton
