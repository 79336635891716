import React from 'react'
import { StyledButton, Text, GifImage } from './styles'
import Gif from '@/assets/images/Check.gif'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SubmitButton = ({ showPopup }) => {
  const { t } = useTranslation()
  const { surveyId } = useParams()
  const history = useHistory()
  const {
    answered,
    unanswered,
    answerLoading,
    attachedQuestionAnswerCompulsory
  } = useSelector(state => state.answer)

  const confectioneryClick = e => {
    e.preventDefault()
    unanswered || attachedQuestionAnswerCompulsory
      ? showPopup(true)
      : history.push(`/survey/${surveyId}/review`)
  }

  return (
    <Link onClick={confectioneryClick} to='#'>
      <StyledButton type='primary' disabled={!answered.length || answerLoading}>
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.submit`)}
        </Text>
        {answered.length ? <GifImage src={Gif} /> : null}
      </StyledButton>
    </Link>
  )
}

const SubmitButtonMobile = ({ showPopup }) => {
  const { t } = useTranslation()
  const { surveyId } = useParams()
  const history = useHistory()
  const {
    answered,
    unanswered,
    answerLoading,
    attachedQuestionAnswerCompulsory
  } = useSelector(state => state.answer)

  const confectioneryClick = e => {
    e.preventDefault()
    unanswered || attachedQuestionAnswerCompulsory
      ? showPopup(true)
      : history.push(`/survey/${surveyId}/review`)
  }

  return (
    <Link onClick={confectioneryClick} to='#'>
      <StyledButton
        type='primary'
        mobile={'true'}
        disabled={!answered.length || answerLoading}
      >
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.submit`)}
        </Text>
      </StyledButton>
    </Link>
  )
}

export { SubmitButton, SubmitButtonMobile }
