import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'answer',
  initialState: {
    answers: {},
    answered: [],
    answerLoading: false,
    unanswered: false,
    confectionaryDesc: null,
    attachedQuestionAnswer: {},
    attachedQuestionAnswerCompulsory: false
  },
  reducers: {
    setAnswers: (state, action) => {
      state.answers = action.payload
    },
    setAnswered: (state, action) => {
      state.answered = action.payload
    },
    setAnswerLoading: (state, action) => {
      state.answerLoading = action.payload
    },
    setUnanswered: (state, action) => {
      state.unanswered = action.payload
    },
    setConfectionaryDesc: (state, action) => {
      state.confectionaryDesc = action.payload
    },
    setAttachedQuestionAnswer: (state, action) => {
      state.attachedQuestionAnswer = action.payload
    },
    setAttachedQuestionAnswerCompulsory: (state, action) => {
      state.attachedQuestionAnswerCompulsory = action.payload
    }
  }
})

export const {
  setAnswers,
  setAnswered,
  setAnswerLoading,
  setUnanswered,
  setConfectionaryDesc,
  setAttachedQuestionAnswer,
  setAttachedQuestionAnswerCompulsory
} = slice.actions

export default slice.reducer
