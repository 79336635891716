import { combineReducers } from 'redux'
import userReducer from './users'
import surveyReducer from './surveys'
import answerReducer from './answers'
import controlsReducer from './UIControls'

const reducer = combineReducers({
  // here we will be adding reducers
  user: userReducer,
  survey: surveyReducer,
  answer: answerReducer,
  controls: controlsReducer
})

export default reducer
