import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles'
import { useSelector } from 'react-redux'

export const DEFAULT_THEME = {
  APP_TITLE: 'Flavorwiki',
  ICON_URL: 'https://tools.flavorwiki.com/default.png',
  FULL_LOGO_URL:
    'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
  PRIMARY_COLOR: '#90CBE5',
  PRIMARY_TEXT_COLOR: '#7ECDE9',
  WHITE: '#ffffff',
  GRAY_TEXT: '#A9A5A5',
  SIDE_BAR_GRAY: '#F7F7FC',
  BLUE_TEXT: '#183B58',
  HEADER_BORDER_COLOR: '#E5EAF4',
  SIDER_DEFAULT_BG: '#f7f7fc',
  BUNGE_GREEN: '#A4C654',
  DEFAULT_TEXT: '#183B57',
  DARK: '#000000',
  DARK_TEXT: '#2D2D2D',
  PARAGRAPH_TEXT: '#272D4E',
  RED: '#EB5757',
  DEFAULT_INPUT_BORDER: '#d9d9d9',
  DEACTIVE_BUTTON: '#C9CDD6',
  TEXT_CONTAINER_BG: '#F9FBFD'
}
export const CONFECTIONERY_THEME = {
  APP_TITLE: 'Flavorwiki',
  ICON_URL: 'https://tools.flavorwiki.com/default.png',
  FULL_LOGO_URL:
    'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
  PRIMARY_COLOR: '#183B57',
  PRIMARY_TEXT_COLOR: '#183B57',
  // PRIMARY_COLOR: '#90CBE5',
  // PRIMARY_TEXT_COLOR: '#7ECDE9',
  WHITE: '#ffffff',
  GRAY_TEXT: '#A9A5A5',
  SIDE_BAR_GRAY: '#F7F7FC',
  BLUE_TEXT: '#183B58',
  HEADER_BORDER_COLOR: '#E5EAF4',
  SIDER_DEFAULT_BG: '#f7f7fc',
  BUNGE_GREEN: '#A4C654',
  DEFAULT_TEXT: '#183B57',
  DARK: '#000000',
  DARK_TEXT: '#2D2D2D',
  PARAGRAPH_TEXT: '#272D4E',
  RED: '#EB5757',
  SELECTION_COLOR: '#90CBE5',
  DEFAULT_INPUT_BORDER: '#d9d9d9',
  DEACTIVE_BUTTON: '#C9CDD6',
  TEXT_CONTAINER_BG: '#F9FBFD'
}
const THEMES = {
  default: { ...DEFAULT_THEME },
  Confectionery: { ...CONFECTIONERY_THEME }
}

const Theme = ({ children }) => {
  const { theme } = useSelector(state => state.survey)

  const APP_THEME = useMemo(
    () => (!THEMES[theme] ? THEMES['default'] : THEMES[theme]),
    [theme]
  )

  return (
    <ThemeProvider theme={APP_THEME}>
      {children}
      <GlobalStyle theme={APP_THEME} />
    </ThemeProvider>
  )
}

export default Theme
