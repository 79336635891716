import React from 'react'
import { AntButton } from './styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const StartButton = ({ survey, isConfectionery }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const url = isConfectionery
    ? `/survey/${survey.id}/preSurveyForm`
    : `/survey/${survey.id}/product`
  return (
    <AntButton type='primary' onClick={() => history.push(url)}>
      {t(`components.${process.env.REACT_APP_THEME}.button.start`)}
    </AntButton>
  )
}
export default StartButton
